import { defineComponent, computed, inject } from '@vue/composition-api'
import { useTimeTracker } from '../composables/useTimeTracker'
import Record from '../shared/record.vue'
import { state }  from "../store"

export default defineComponent({
  components: {
    Record
  },
  props: {
    failedAnswer: {
      type: Object,
      required: true,
    }
  },
  setup (_, ctx) {
    
    const time = computed(() => state.game.next_solve_time_by_second || 0)
    const { isoTime } = useTimeTracker(time)
    
    const canContinue = computed(() => time.value <= 0)
    const $stage = inject("$stage")
    
    const reset = () => {
      window.location.href = window.location.origin + "/game/yama?group_id=" + ctx.root.$route.query.group_id
    }

    return {
      canContinue,
      isoTime,
      $stage,
      reset
    }
  },
})